@charset "UTF-8";

@import './_variables.scss';

html,
body {
  font-size: 100%;
  max-width: 100vw;
  overflow-x: hidden;
  word-wrap: break-word;

  font-family: var(--font-inter), sans-serif;
  font-size: 14px;
  line-height: 22px;
  letter-spacing: 0.3px;
  color: var(--white);
  background-color: var(--unique-background);
  margin: 0;
  padding: 0;
}

a {
  color: inherit;
  text-decoration: none;
}

ul {
  margin: 0;
  padding: 0;
  list-style-type: none;
}

[type='checkbox'],
[type='radio'] {
  outline: none;
}

/* Главный контейнер скроллбара */
::-webkit-scrollbar {
  width: 4px;
  height: 4px;
}

/* Вертикальный бегунок */
::-webkit-scrollbar-thumb {
  background: var(--steel-70);
  border-radius: 6px;
}

/* Горизонтальный бегунок */
::-webkit-scrollbar-thumb:horizontal {
  background: var(--steel-70);
  border-radius: 6px;
}

/* Место стыка скролла */
::-webkit-scrollbar-corner {
  background-color: #3b3c41;
}

body {
  margin: 0;
  padding: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow-x: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

* {
  font-family: var(--font-inter) !important;
  letter-spacing: 1px !important;
  box-sizing: border-box;
  font-weight: 400;
  margin: 0;
  padding: 0;
}
